import * as React from "react"
import { Box } from "theme-ui"
import { graphql } from "gatsby"

import headingImage from "../images/img18.svg"
import { useAllTags } from "../components"
import { Tags } from "../components/modern/buttons"

import {
  PaddedContent,
  PageHeading,
  PageWrapper,
} from "../components/modern/layout"
import { BlogPostsGrid } from "../components/blog/posts-grid"

const TagPage = ({ data, pageContext }) => {
  const allTags = useAllTags({ withLinks: true, appendGeneral: `all` })
  const { tag } = pageContext
  const { edges, totalCount } = data.allMarkdownRemark
  const title = `Blog: ${tag} (${totalCount} post${
    totalCount === 1 ? `` : `s`
  })`

  return (
    <PageWrapper title="Engineering blog" pathname="/blog">
      <PageHeading title={title} imageSrc={headingImage}>
        <Box sx={{ fontSize: `.9rem` }}>
          <Tags tags={allTags} />
        </Box>
      </PageHeading>
      <PaddedContent
        customSx={{
          maxWidth: `90em`,
          marginX: `auto`,
        }}
      >
        <BlogPostsGrid posts={edges} showPatterns={false} />
      </PaddedContent>
    </PageWrapper>
  )
}

export default TagPage

export const pageQuery = graphql`
  query ($tag: String) {
    allMarkdownRemark(
      sort: { fields: [fields___slug], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      totalCount
      edges {
        node {
          excerpt(pruneLength: 280)
          fields {
            date(formatString: "D MMMM YYYY")
            slug
          }
          frontmatter {
            title
            shortTitle
            description
            tags
          }
        }
      }
    }
  }
`
